// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._tt-230632860{text-transform:var(--f-tr-4);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._col-675002279{color:var(--color);}
:root ._ta-center{text-align:center;}
:root ._col-314282491{color:var(--text);}
:root ._col-1103916793{color:var(--textSubdued);}`, "",{"version":3,"sources":["webpack://./../../packages/components/src/actions/SegmentControl/index.tsx"],"names":[],"mappings":"AAAA,mBAAmB,cAAc,CAAC;AAClC,uBAAuB,qBAAqB,CAAC;AAC7C,sBAAsB,oBAAoB,CAAC;AAC3C,4BAA4B,oBAAoB,CAAC;AACjD,eAAe,cAAc,CAAC;AAC9B,eAAe,gBAAgB,CAAC;AAChC,eAAe,iBAAiB,CAAC;AACjC,eAAe,eAAe,CAAC;AAC/B,qBAAqB,4BAA4B,CAAC;AAClD,qBAAqB,uBAAuB,CAAC;AAC7C,sBAAsB,yBAAyB,CAAC;AAChD,qBAAqB,4BAA4B,CAAC;AAClD,sBAAsB,uBAAuB,CAAC;AAC9C,qBAAqB,yBAAyB,CAAC;AAC/C,sBAAsB,kBAAkB,CAAC;AACzC,kBAAkB,iBAAiB,CAAC;AACpC,sBAAsB,iBAAiB,CAAC;AACxC,uBAAuB,wBAAwB,CAAC","sourcesContent":[":root ._dsp-inline{display:inline;}\n:root ._bxs-border-box{box-sizing:border-box;}\n:root ._ww-break-word{word-wrap:break-word;}\n:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}\n:root ._mt-0px{margin-top:0px;}\n:root ._mr-0px{margin-right:0px;}\n:root ._mb-0px{margin-bottom:0px;}\n:root ._ml-0px{margin-left:0px;}\n:root ._tt-230632860{text-transform:var(--f-tr-4);}\n:root ._ff-299667014{font-family:var(--f-fa);}\n:root ._fow-233016140{font-weight:var(--f-we-4);}\n:root ._ls-167744059{letter-spacing:var(--f-21-4);}\n:root ._fos-229441220{font-size:var(--f-si-4);}\n:root ._lh-222976573{line-height:var(--f-li-4);}\n:root ._col-675002279{color:var(--color);}\n:root ._ta-center{text-align:center;}\n:root ._col-314282491{color:var(--text);}\n:root ._col-1103916793{color:var(--textSubdued);}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
