// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._pt-1481558400{padding-top:var(--space-1);}
:root ._pr-1481558400{padding-right:var(--space-1);}
:root ._pb-1481558400{padding-bottom:var(--space-1);}
:root ._pl-1481558400{padding-left:var(--space-1);}
:root ._mt-1481558400{margin-top:var(--space-1);}
:root ._mr-1481558400{margin-right:var(--space-1);}
:root ._mb-1481558400{margin-bottom:var(--space-1);}
:root ._ml-1481558400{margin-left:var(--space-1);}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-0px{border-bottom-width:0px;}
:root ._blw-0px{border-left-width:0px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}`, "",{"version":3,"sources":["webpack://./../../packages/components/src/forms/Form/index.tsx"],"names":[],"mappings":"AAAA,iBAAiB,YAAY,CAAC;AAC9B,mBAAmB,mBAAmB,CAAC;AACvC,gBAAgB,eAAe,CAAC;AAChC,uBAAuB,qBAAqB,CAAC;AAC7C,qBAAqB,iBAAiB,CAAC;AACvC,gBAAgB,cAAc,CAAC;AAC/B,gBAAgB,aAAa,CAAC;AAC9B,aAAa,aAAa,CAAC;AAC3B,kBAAkB,qBAAqB,CAAC;AACxC,sBAAsB,0BAA0B,CAAC;AACjD,sBAAsB,4BAA4B,CAAC;AACnD,sBAAsB,6BAA6B,CAAC;AACpD,sBAAsB,2BAA2B,CAAC;AAClD,sBAAsB,yBAAyB,CAAC;AAChD,sBAAsB,2BAA2B,CAAC;AAClD,sBAAsB,4BAA4B,CAAC;AACnD,sBAAsB,0BAA0B,CAAC;AACjD,gBAAgB,oBAAoB,CAAC;AACrC,gBAAgB,sBAAsB,CAAC;AACvC,gBAAgB,uBAAuB,CAAC;AACxC,gBAAgB,qBAAqB,CAAC;AACtC,kBAAkB,yBAAyB,CAAC;AAC5C,kBAAkB,sBAAsB,CAAC;AACzC,kBAAkB,uBAAuB,CAAC;AAC1C,kBAAkB,wBAAwB,CAAC","sourcesContent":[":root ._dsp-flex{display:flex;}\n:root ._ai-stretch{align-items:stretch;}\n:root ._fb-auto{flex-basis:auto;}\n:root ._bxs-border-box{box-sizing:border-box;}\n:root ._pos-relative{position:relative;}\n:root ._mih-0px{min-height:0px;}\n:root ._miw-0px{min-width:0px;}\n:root ._fs-0{flex-shrink:0;}\n:root ._fd-column{flex-direction:column;}\n:root ._pt-1481558400{padding-top:var(--space-1);}\n:root ._pr-1481558400{padding-right:var(--space-1);}\n:root ._pb-1481558400{padding-bottom:var(--space-1);}\n:root ._pl-1481558400{padding-left:var(--space-1);}\n:root ._mt-1481558400{margin-top:var(--space-1);}\n:root ._mr-1481558400{margin-right:var(--space-1);}\n:root ._mb-1481558400{margin-bottom:var(--space-1);}\n:root ._ml-1481558400{margin-left:var(--space-1);}\n:root ._btw-0px{border-top-width:0px;}\n:root ._brw-0px{border-right-width:0px;}\n:root ._bbw-0px{border-bottom-width:0px;}\n:root ._blw-0px{border-left-width:0px;}\n:root ._bbs-solid{border-bottom-style:solid;}\n:root ._bts-solid{border-top-style:solid;}\n:root ._bls-solid{border-left-style:solid;}\n:root ._brs-solid{border-right-style:solid;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
