
      import API from "!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/tamagui-loader/dist/cjs/css.js?cssData=OnJvb3QgLl9kc3AtaW5saW5le2Rpc3BsYXk6aW5saW5lO30KOnJvb3QgLl9ieHMtYm9yZGVyLWJveHtib3gtc2l6aW5nOmJvcmRlci1ib3g7fQo6cm9vdCAuX3d3LWJyZWFrLXdvcmR7d29yZC13cmFwOmJyZWFrLXdvcmQ7fQo6cm9vdCAuX3doaXRlU3BhY2UtcHJlLXdyYXB7d2hpdGUtc3BhY2U6cHJlLXdyYXA7fQo6cm9vdCAuX210LTBweHttYXJnaW4tdG9wOjBweDt9Cjpyb290IC5fbXItMHB4e21hcmdpbi1yaWdodDowcHg7fQo6cm9vdCAuX21iLTBweHttYXJnaW4tYm90dG9tOjBweDt9Cjpyb290IC5fbWwtMHB4e21hcmdpbi1sZWZ0OjBweDt9Cjpyb290IC5fdHQtMjMwNjMyODYwe3RleHQtdHJhbnNmb3JtOnZhcigtLWYtdHItNCk7fQo6cm9vdCAuX2ZmLTI5OTY2NzAxNHtmb250LWZhbWlseTp2YXIoLS1mLWZhKTt9Cjpyb290IC5fZm93LTIzMzAxNjE0MHtmb250LXdlaWdodDp2YXIoLS1mLXdlLTQpO30KOnJvb3QgLl9scy0xNjc3NDQwNTl7bGV0dGVyLXNwYWNpbmc6dmFyKC0tZi0yMS00KTt9Cjpyb290IC5fZm9zLTIyOTQ0MTIyMHtmb250LXNpemU6dmFyKC0tZi1zaS00KTt9Cjpyb290IC5fbGgtMjIyOTc2NTcze2xpbmUtaGVpZ2h0OnZhcigtLWYtbGktNCk7fQo6cm9vdCAuX2NvbC02NzUwMDIyNzl7Y29sb3I6dmFyKC0tY29sb3IpO30KOnJvb3QgLl90YS1jZW50ZXJ7dGV4dC1hbGlnbjpjZW50ZXI7fQo6cm9vdCAuX2NvbC0zMTQyODI0OTF7Y29sb3I6dmFyKC0tdGV4dCk7fQo6cm9vdCAuX2NvbC0xMTAzOTE2Nzkze2NvbG9yOnZhcigtLXRleHRTdWJkdWVkKTt9!./index.tsx";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/tamagui-loader/dist/cjs/css.js?cssData=OnJvb3QgLl9kc3AtaW5saW5le2Rpc3BsYXk6aW5saW5lO30KOnJvb3QgLl9ieHMtYm9yZGVyLWJveHtib3gtc2l6aW5nOmJvcmRlci1ib3g7fQo6cm9vdCAuX3d3LWJyZWFrLXdvcmR7d29yZC13cmFwOmJyZWFrLXdvcmQ7fQo6cm9vdCAuX3doaXRlU3BhY2UtcHJlLXdyYXB7d2hpdGUtc3BhY2U6cHJlLXdyYXA7fQo6cm9vdCAuX210LTBweHttYXJnaW4tdG9wOjBweDt9Cjpyb290IC5fbXItMHB4e21hcmdpbi1yaWdodDowcHg7fQo6cm9vdCAuX21iLTBweHttYXJnaW4tYm90dG9tOjBweDt9Cjpyb290IC5fbWwtMHB4e21hcmdpbi1sZWZ0OjBweDt9Cjpyb290IC5fdHQtMjMwNjMyODYwe3RleHQtdHJhbnNmb3JtOnZhcigtLWYtdHItNCk7fQo6cm9vdCAuX2ZmLTI5OTY2NzAxNHtmb250LWZhbWlseTp2YXIoLS1mLWZhKTt9Cjpyb290IC5fZm93LTIzMzAxNjE0MHtmb250LXdlaWdodDp2YXIoLS1mLXdlLTQpO30KOnJvb3QgLl9scy0xNjc3NDQwNTl7bGV0dGVyLXNwYWNpbmc6dmFyKC0tZi0yMS00KTt9Cjpyb290IC5fZm9zLTIyOTQ0MTIyMHtmb250LXNpemU6dmFyKC0tZi1zaS00KTt9Cjpyb290IC5fbGgtMjIyOTc2NTcze2xpbmUtaGVpZ2h0OnZhcigtLWYtbGktNCk7fQo6cm9vdCAuX2NvbC02NzUwMDIyNzl7Y29sb3I6dmFyKC0tY29sb3IpO30KOnJvb3QgLl90YS1jZW50ZXJ7dGV4dC1hbGlnbjpjZW50ZXI7fQo6cm9vdCAuX2NvbC0zMTQyODI0OTF7Y29sb3I6dmFyKC0tdGV4dCk7fQo6cm9vdCAuX2NvbC0xMTAzOTE2Nzkze2NvbG9yOnZhcigtLXRleHRTdWJkdWVkKTt9!./index.tsx";
       export default content && content.locals ? content.locals : undefined;
